import useBoolean from 'hooks/useBoolean'
import { useCallback } from 'react'
import { Resolver, useForm } from 'react-hook-form'

export const defaultValues = {
  roomName: '',
}

export type RoomFormValues = typeof defaultValues

export const resolver: Resolver<RoomFormValues> = async (values) => {
  return {
    values,
    errors: !values.roomName
      ? {
          roomName: {
            type: 'required',
            message: 'We need that name 😬',
          },
        }
      : {},
  }
}

export function useRoomFormDialog(defaultFormValues = defaultValues) {
  const [isDialogOpen, openDialog, closeDialog] = useBoolean()
  const { handleSubmit, control, reset } = useForm<RoomFormValues>({
    defaultValues: defaultFormValues,
    resolver,
  })
  const closeAndReset = useCallback(() => {
    closeDialog()
    reset()
  }, [closeDialog, reset])

  return {
    handleSubmit,
    control,
    reset,
    isDialogOpen,
    openDialog,
    closeDialog,
    closeAndReset,
  }
}
