import {
  AppBar,
  Avatar,
  Box,
  Button,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  Toolbar,
  Typography,
} from '@mui/material'
import useFirebase from 'hooks/useFirebase'
import Link from 'next/link'
import { createElement, useCallback, useState } from 'react'
import { useUserContext } from 'src/providers/UserProvider'
import Logo from './Logo'
import SignInDialog from './SignInDialog'

interface NavbarProps {
  children?: any
  signIn?: boolean
  position?: 'fixed' | 'absolute' | 'sticky' | 'static' | 'relative'
  menu?: () => JSX.Element
}

export default function Navbar({
  children,
  menu,
  position = 'fixed',
  signIn,
}: NavbarProps) {
  const { user } = useUserContext()
  const { auth } = useFirebase()
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const [showSignInPopup, setShowSignInPopup] = useState(false)

  const handleMenu = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget)
    },
    []
  )

  const handleClose = useCallback(() => {
    setAnchorEl(null)
  }, [])

  const handleSignOut = useCallback(() => {
    handleClose()
    auth.signOut()
  }, [auth, handleClose])

  const onCloseSignInPopup = useCallback(() => {
    setShowSignInPopup(false)
  }, [])

  return (
    <>
      <AppBar position={position}>
        <Toolbar>
          <Box>
            {menu ? (
              <Stack direction="row" alignItems="center">
                {createElement(menu)}
                <Link href="/" passHref>
                  <Typography
                    variant="h5"
                    color="primary"
                    sx={{ marginLeft: 1, cursor: 'pointer' }}
                  >
                    Zealous
                  </Typography>
                </Link>
              </Stack>
            ) : (
              <Link href="/" passHref>
                <IconButton
                  size="small"
                  aria-label="home"
                  aria-controls="menu-appbar"
                  sx={{ padding: 0 }}
                >
                  <Logo container={{ sx: { height: '50px' } }} />
                </IconButton>
              </Link>
            )}
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexGrow: 1,
              justifyContent: 'flex-end',
              alignItems: 'center',
            }}
          >
            {children}
            {user && (
              <>
                <IconButton
                  size="large"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleMenu}
                >
                  <Avatar
                    alt={user.displayName || ''}
                    src={user.photoURL || ''}
                  />
                </IconButton>

                <Menu
                  id="menu-appbar"
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                >
                  <MenuItem onClick={handleSignOut}>Sign out</MenuItem>
                </Menu>
              </>
            )}
            {!user && signIn && (
              <Button
                onClick={() => {
                  setShowSignInPopup(true)
                }}
              >
                Sign in
              </Button>
            )}
          </Box>
        </Toolbar>
      </AppBar>
      <SignInDialog
        open={showSignInPopup}
        onClose={onCloseSignInPopup}
        onSuccess={onCloseSignInPopup}
      />
    </>
  )
}
