import { Box, BoxProps } from '@mui/material'

interface LogoProps {
  backgroundColor?: string
  color?: string
  container?: BoxProps
}

function Logo({ backgroundColor, color, container }: LogoProps) {
  return (
    <Box {...container}>
      <svg
        width="50"
        height="50"
        viewBox="0 0 135 135"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          cx="67.4571"
          cy="67.4571"
          r="67.4571"
          fill={backgroundColor ?? 'white'}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M105.253 36.1606H90.2094L81.7106 44.9474C74.3724 39.5893 67.5713 36.4684 60.3976 36.5051C52.7798 36.5442 45.7079 40.1402 38.0924 45.9901L37.2276 46.6544L43.0004 55.4467L43.8652 54.7825C50.8219 49.4386 55.8465 47.3125 60.4449 47.289C64.3619 47.2689 68.6723 48.7716 74.3089 52.5997L29.4008 99.0288H44.444L52.9888 90.1946C60.1058 95.4539 66.7972 98.6476 73.9738 98.6844C81.5938 98.7234 88.7941 95.1996 96.6655 89.2143L97.5332 88.5545L91.7994 79.7324L90.9317 80.3921C83.7528 85.8509 78.6173 87.9241 74.0211 87.9005C70.0984 87.8804 65.8635 86.3303 60.3803 82.5528L105.253 36.1606Z"
          fill={color ?? '#F93642'}
        />
      </svg>
    </Box>
  )
}

export default Logo
