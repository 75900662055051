import { useCallback, useState } from 'react'

/**
 * Hook that stores a boolean state and returns callbacks to set it to true and false.
 *
 * @example
 * const [isOpen, onOpen, onClose] = useBoolean()
 *
 * return (
 *   <>
 *     <Button onPress={onOpen} />
 *     {isOpen && <Dialog onClose={onClose} />}
 *   </>
 * )
 */
export default function useBoolean(
  initialValue?: boolean
): [boolean, () => void, () => void] {
  const [value, setValue] = useState(initialValue || false)

  const on = useCallback(() => {
    setValue(true)
  }, [])

  const off = useCallback(() => {
    setValue(false)
  }, [])

  return [value, on, off]
}
