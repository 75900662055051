import { Dialog, DialogContent, DialogTitle, IconButton } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import dynamic from 'next/dynamic'
import Loading from './Loading'

const SignIn = dynamic(() => import('components/SignIn'), {
  ssr: false,
  loading: () => <Loading height="100%" p={3} />,
})

interface SignInDialogProps {
  open: boolean
  onClose: () => void
  onSuccess?: () => void
}

export default function SignInDialog({
  open,
  onClose,
  onSuccess,
}: SignInDialogProps) {
  return (
    <Dialog open={open}>
      <DialogTitle>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <SignIn sx={{ p: 2 }} onSuccess={onSuccess} />
      </DialogContent>
    </Dialog>
  )
}
