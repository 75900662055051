import { MoreVert } from '@mui/icons-material'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import {
  Avatar,
  Button,
  Card,
  CardActions,
  CardHeader,
  IconButton,
  Link,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
} from '@mui/material'
import ConfirmDialog from 'components/ConfirmDialog'
import { format } from 'date-fns'
import { deleteDoc, doc, Timestamp, updateDoc } from 'firebase/firestore'
import useBoolean from 'hooks/useBoolean'
import useFirebase from 'hooks/useFirebase'
import { useRouter } from 'next/router'
import { useCallback, useMemo, useState } from 'react'
import Room from 'src/models/Room'
import { RoomFormValues, useRoomFormDialog } from './form'
import RoomDialog from './RoomDialog'

interface RoomCardProps {
  room: Room
}

const cardStyle = { margin: 2 }

export default function RoomCard({ room }: RoomCardProps) {
  const { db } = useFirebase()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [isConfirmDialogOpen, openConfirmDialog, closeConfirmDialog] =
    useBoolean()
  const isMenuOpen = Boolean(anchorEl)
  const { push } = useRouter()
  const { handleSubmit, control, isDialogOpen, openDialog, closeAndReset } =
    useRoomFormDialog({ roomName: room.name })

  const onSubmit = handleSubmit((data) => editRoom(data))

  const deleteRoom = useCallback(() => {
    const roomRef = doc(db, 'rooms', room.id)
    deleteDoc(roomRef)
  }, [db, room.id])

  const openSettings = useCallback((event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }, [])

  const closeSettings = useCallback(() => {
    setAnchorEl(null)
  }, [])

  const openEdit = useCallback(() => {
    openDialog()
    closeSettings()
  }, [openDialog, closeSettings])

  const openRemove = useCallback(() => {
    openConfirmDialog()
    closeSettings()
  }, [openConfirmDialog, closeSettings])

  async function editRoom(values: RoomFormValues) {
    try {
      const roomRef = doc(db, 'rooms', room.id)
      await updateDoc(roomRef, {
        name: values.roomName,
      })
      closeAndReset()
    } catch (e) {
      // TODO: open snackbar with an error
    }
  }

  const copyRoomUrl = useCallback(() => {
    navigator.clipboard.writeText(`${location.origin}/room/${room.id}`)
  }, [room.id])

  const goToRoom = useCallback(() => push(`/room/${room.id}`), [push, room.id])

  const { avatarUrl, name } = room.creator ?? {}
  const avatar = useMemo(
    () => avatarUrl && name && <Avatar alt={name} src={avatarUrl} />,
    [avatarUrl, name]
  )

  const title = useMemo(
    () => (
      <Link href={`/room/${room.id}`} sx={{ textDecoration: 'none' }}>
        {room.name}
      </Link>
    ),
    [room.id, room.name]
  )

  const settings = useMemo(() => {
    if (room.twitterSpaceId) return

    return (
      <IconButton aria-label="settings" onClick={openSettings}>
        <MoreVert />
      </IconButton>
    )
  }, [openSettings, room])

  return (
    <Card key={room.id} sx={cardStyle}>
      <CardHeader
        avatar={avatar}
        action={settings}
        title={title}
        subheader={formatDate(room.startedAt ?? room.createdAt)}
        href={`/room/${room.id}}`}
      />
      <CardActions>
        <Button onClick={goToRoom} variant="text">
          Open
        </Button>
        <Button onClick={copyRoomUrl} variant="text">
          Share
        </Button>
      </CardActions>

      <Menu
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        anchorEl={anchorEl}
        open={isMenuOpen}
        onClose={closeSettings}
      >
        <MenuItem onClick={openEdit}>
          <ListItemIcon>
            <EditIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Edit</ListItemText>
        </MenuItem>
        <MenuItem onClick={openRemove}>
          <ListItemIcon>
            <DeleteIcon fontSize="small" color="primary" />
          </ListItemIcon>
          <Typography color="primary">Delete</Typography>
        </MenuItem>
      </Menu>

      <RoomDialog
        editing={true}
        control={control}
        isOpen={isDialogOpen}
        onClose={closeAndReset}
        onSubmit={onSubmit}
      />
      <ConfirmDialog
        isOpen={isConfirmDialogOpen}
        onClose={closeConfirmDialog}
        confirm={deleteRoom}
        refuse={closeConfirmDialog}
      />
    </Card>
  )
}

const formatDate = (date: Timestamp) =>
  format(date.toDate(), 'MMMM dd, yyyy hh:mmaaa')
