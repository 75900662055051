import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'

interface ConfirmDialogProps {
  isOpen: boolean
  onClose: () => void
  confirm: () => void
  refuse: () => void
}

export default function ConfirmDialog({
  isOpen,
  onClose,
  confirm,
  refuse,
}: ConfirmDialogProps) {
  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>
        <Typography>Are you sure about this?</Typography>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent
        sx={{
          display: 'flex',
          justifyContent: 'center',
          minWidth: {
            xs: '250px',
            md: '500px',
          },
          fontSize: '48px',
        }}
      >
        🤔
      </DialogContent>
      <DialogActions>
        <Button onClick={confirm}>Do it!</Button>
        <Button onClick={refuse}>Noo, lemme back</Button>
      </DialogActions>
    </Dialog>
  )
}
