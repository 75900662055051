import {
  Button,
  DialogActions,
  Dialog,
  TextField,
  DialogTitle,
  DialogContent,
  IconButton,
  Typography,
} from '@mui/material'
import { Control, Controller } from 'react-hook-form'
import { RoomFormValues } from './form'
import CloseIcon from '@mui/icons-material/Close'

interface NewRoomModalProps {
  editing?: boolean
  control?: Control<RoomFormValues, object>
  onClose: () => void
  isOpen: boolean
  onSubmit: () => void
}

export default function RoomDialog({
  editing = false,
  control,
  onClose,
  isOpen,
  onSubmit,
}: NewRoomModalProps) {
  return (
    <Dialog open={isOpen} onClose={onClose}>
      <form onSubmit={onSubmit}>
        <DialogTitle>
          <Typography>New Meeting</Typography>
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Controller
            control={control}
            name="roomName"
            render={({ field, fieldState }) => (
              <TextField
                variant="standard"
                helperText={fieldState.error?.message}
                label="Meeting Name"
                value={field.value}
                error={!!fieldState.error}
                onChange={field.onChange}
                sx={{ minWidth: { xs: '250px', sm: '500px ' } }}
              />
            )}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onSubmit} type="submit">
            {editing ? 'Save' : 'Start'}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}
